import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import {createApp} from 'vue'
import naive from "naive-ui";
import {createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'

import Registration from "./Registration.vue";
import Login from './Login.vue'
import WaitPage from "./WaitPage.vue";

import ConstructorView from "./company/ConstructorView";
import CompanyProfile from './company/Profile'
import CompanyProfileEdit from './company/ProfileEdit'
import CompanyPasswordEdit from './company/PasswordEdit'
import CompanyProjectPage from './company/Project'
import CompanyOrders from './company/Orders'
import CompanyOfferPage from './company/Offer'
import CompanyInstructionText from './company/InstructionText'
import CompanyInstructionVideo from './company/InstructionVideo'
import CompanyFaq from './company/FAQ'


import Constructor from "./customer/Constructor.vue";
import CustomerProfile from './customer/Profile'
import CustomerProfileEdit from './customer/ProfileEdit'
import CustomerPasswordEdit from './customer/PasswordEdit'
import CustomerOrders from './customer/Orders'
import CustomerOrderPage from './customer/Order'
import CustomerOfferPage from "./customer/Offer"
import CustomerInstructionText from './customer/InstructionText'
import CustomerInstructionVideo from './customer/InstructionVideo'
import CustomerFaq from './customer/FAQ'

import AdminConstructor from "./admin/AdminConstructor";
import AdminLogin from "./admin/AdminLogin";
import AdminProjects from "./admin/Projects";
import CreateManager from "./admin/CreateManager";
import ProfileControl from "./admin/ProfileControl"
import Translates from "./admin/Translates"
import ModelList from './admin/ModelList.vue'
import Customers from './admin/Customers'
import CustomersChat from './admin/CustomersChat'
import Companies from './admin/Companies'
import CompaniesChat from './admin/CompaniesChat'
import CompanyEditor from './admin/CompanyEditor'
import CustomerEditor from './admin/CustomerEditor'
import ModelEditor from './admin/ModelEditor.vue'
import NewApplication from './admin/NewApplications.vue'
import LoadModel from "./admin/LoadModel.vue";
import ConstructorSettings from "./admin/ConstructorSettings";
import CustomerChat from "./admin/CustomerChat"
import OneCustomerChat from "./admin/OneCustomerChat";
import OneCompanyChat from "./admin/OneCompanyChat";
import Managers from "./admin/Managers"
import CompanyChat from "./admin/CompanyChat"
import SelectCompanyForOrder from "./admin/SelectCompanyForOrder"
import OfferEdit from "./admin/OfferEdit"
import ManagerEditor from "./admin/ManagerEditor"
import AdminGetManagerCustomers from "./admin/ManagerCustomers"

import ManagerCustomersPage from "./admin/ManagerPages/ManagerCustomersPage";
import ManagerCustomerChat from "./admin/ManagerPages/ManagerCustomerChat";
import ManagerConstructor from "./admin/ManagerPages/ManagerConstructor";
import ManagerProjects from "./admin/ManagerPages/Projects";
import ManagerCustomers from "./admin/ManagerPages/ManagerCustomers";
import ManagerOneCustomerChat from "./admin/ManagerPages/OneCustomerChat";

const router = createRouter({
    routes: [
        {
            path: "/",
            redirect: "/login"
        },
        {
            path: "/constructor",
            name: "Конструктор",
            component: Constructor,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/admin/constructor",
            name: "Конструктор администратора",
            component: AdminConstructor,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/constructor_view",
            name: "Просмотр макета",
            component: ConstructorView,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/customer/offer",
            name: "Оферта заказчика",
            component: CustomerOfferPage,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/instruction_text",
            name: "Текстовая инструкция",
            component: CustomerInstructionText,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/instruction_video",
            name: "Видео-инструкция",
            component: CustomerInstructionVideo,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/faq",
            name: "F.A.Q.",
            component: CustomerFaq,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/profile",
            name: "Личный кабинет заказчика",
            component: CustomerProfile,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/profile_edit",
            name: "Изменение данных заказчика",
            component: CustomerProfileEdit,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/password_edit",
            name: "Изменение пароля заказчика",
            component: CustomerPasswordEdit,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/orders",
            name: "Мои заказы",
            component: CustomerOrders,
            meta: {
                requiresCustomerAuth: true,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/customer/order/:id",
            name: "Заказ",
            component: CustomerOrderPage,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/profile",
            name: "Личный кабинет производителя",
            component: CompanyProfile,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/profile_edit",
            name: "Изменение данных производителя",
            component: CompanyProfileEdit,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/password_edit",
            name: "Изменение пароля производителя",
            component: CompanyPasswordEdit,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/faq",
            name: "Часто задаваемые вопосы",
            component: CompanyFaq,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/offer",
            name: "Оферта производителя",
            component: CompanyOfferPage,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/orders",
            name: "Заказы компании",
            component: CompanyOrders,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/project/:id",
            name: "Проект",
            component: CompanyProjectPage,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/instruction_text",
            name: "Текстовая инструкция для компании",
            component: CompanyInstructionText,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/company/instruction_video",
            name: "Видео-инструкция для компании",
            component: CompanyInstructionVideo,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: true,
                requiresAdminAuth: false,
            }
        },


        {
            path: "/login",
            name: "Авторизация",
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/admin/login",
            name: "Админ Панель",
            component: AdminLogin,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/register",
            name: "Регистрация",
            component: Registration,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/wait_page",
            name: "Ожидайте!",
            component: WaitPage,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/admin/load_model",
            name: "Загрузка модели",
            component: LoadModel,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/constructor_settings",
            name: "Настройки конструктора",
            component: ConstructorSettings,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/offer_edit",
            name: "Редактирование оферты",
            component: OfferEdit,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/translates",
            component: Translates,
            name: "Переводы",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/models/:id",
            name: "Редактирование модели",
            component: ModelEditor,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/new_applications",
            name: "Заявки",
            component: NewApplication,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/projects",
            name: "Проекты администратора",
            component: AdminProjects,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/manager_customers_page",
            name: "Ленсиз Заказчики",
            component: ManagerCustomersPage,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/manager_customers_chat/:id",
            name: "Ленсиз Чат",
            component: ManagerCustomerChat,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/manager_constructor",
            name: "Ленсиз Конструктор",
            component: ManagerConstructor,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/manager_customers",
            name: "Мои заказчики",
            component: ManagerCustomers,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/manager_one_customer_chat/:id",
            name: "Чат менеджера и заказчика",
            component: ManagerOneCustomerChat,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/manager_projects",
            name: "Проекты менеджера",
            component: ManagerProjects,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/managers",
            name: "Список менеджеров",
            component: Managers,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/create_manager",
            name: "Добавление менеджера",
            component: CreateManager,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/managers/:id",
            name: "Страница менеджера",
            component: ManagerEditor,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/manager_customers/:id",
            name: "Покупатели менеджера",
            component: AdminGetManagerCustomers,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/models",
            component: ModelList,
            name: "Список моделей",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/profile_control",
            component: ProfileControl,
            name: "Управление профилем администратора",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: '/admin/customers',
            component: Customers,
            name: "Список заказчиков",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/customers_chat",
            component: CustomersChat,
            name: "Список чатов с заказчиками",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/customer_chat/:id",
            component: CustomerChat,
            name: "Чат с заказчиком",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/admin/company_chat/:id",
            component: CompanyChat,
            name: "Чат с производителем",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: false,
            }
        },
        {
            path: "/admin/customers/:id",
            name: "Сведения о заказчике",
            component: CustomerEditor,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/one_customer_chat/:id",
            name: "Чаты заказчика",
            component: OneCustomerChat,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/one_company_chat/:id",
            name: "Чаты производителя",
            component: OneCompanyChat,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/companies",
            component: Companies,
            name: "Список производителей",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/companies_chat",
            component: CompaniesChat,
            name: "Список чатов с производителями",
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/companies/:id",
            name: "Сведения о производителе",
            component: CompanyEditor,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
        {
            path: "/admin/select_company_for_order",
            name: "Выбрать компанию для пошива",
            component: SelectCompanyForOrder,
            meta: {
                requiresCustomerAuth: false,
                requiresCompanyAuth: false,
                requiresAdminAuth: true,
            }
        },
    ],
    history: createWebHistory()
})

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
    // if (to.meta.requiresAdminAuth) {  // ПРОВЕРКА НА АДМИНИСТРАТОРА
    //     const token = localStorage.getItem('access_token');
    //     if (token) {
    //         axios.post("/api/admin/check_access_token", {"token": token},
    //             {
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/json'
    //                 }
    //             }
    //         ).then((response) => {
    //             if (response.status === 200) {
    //                 next();
    //             } else {
    //                 next('/admin/login');
    //             }
    //         }).catch((reason) => {
    //             console.log(reason);
    //             next('/admin/login');
    //         })
    //     } else {
    //         next('/admin/login');
    //     }
    // } else if (to.meta.requiresCompanyAuth) {  // ПРОВЕРКА НА ПОСТАВЩИКА
    //     const token = localStorage.getItem('access_token');
    //     if (token) {
    //         axios.post("/api/company/check_access_token", {"token": token},
    //             {
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/json'
    //                 }
    //             }
    //         ).then((response) => {
    //             if (response.status === 200) {
    //                 next();
    //             } else {
    //                 next('/login');
    //             }
    //         }).catch((reason) => {
    //             console.log(reason);
    //             next('/login');
    //         })
    //     } else {
    //         next('/login');
    //     }
    // } else if (to.meta.requiresCustomerAuth) {  // ПРОВЕРКА НА КЛИЕНТА
    //     const token = localStorage.getItem('access_token');
    //     if (token) {
    //         axios.post("/api/customer/check_access_token", {"token": token},
    //             {
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/json'
    //                 }
    //             }
    //         ).then((response) => {
    //             if (response.status === 200) {
    //                 next();
    //             } else {
    //                 next('/login');
    //             }
    //         }).catch((reason) => {
    //             console.log(reason);
    //             next('/login');
    //         })
    //     } else {
    //         next('/login');
    //     }
    // } else {
    //     next();
    // }
});

const app = createApp(App)
app.use(naive)
app.use(router)
app.mount("#app");
