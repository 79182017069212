<template>
  <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"/>
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
  <HeaderComponent/>
  <div id="wrapper">
    <div class="form-check-inline" style="width: 90vw">
      <div style="float: left">
        <a href="#" id="back_button" style="float: left; font-size: 20px; color: black" @click="this.$router.go(-1)">
          Назад
        </a>
      </div>
      <div style="text-align: center">
        <h3 style="padding-right: 60px">Чат по заказу № {{ this.$route.params["id"] }}</h3>
        <h5>Наименование костюма: {{ order["name"] }} <br>
          Заказчик: {{ order['customer_name'] }} <br>
          {{ user["position"] }}: {{ user["contact_person"] }} {{ user["phone"] }}
        </h5>
        <a class="btn btn-primary" style="margin-top: 5px; margin-right: 5px"
           :href="'/admin/manager_constructor?order=' + this.$route.params['id']">Открыть
          в конструкторе
        </a>
      </div>
      <hr>
      <div class="chat">
        <div class="chat-history">
          <div v-if="this.order.messages" id="chat-box">
            <ul ref="chatBox" v-for="message in Object.keys(this.order.messages)" v-bind:key="message">
              <li class="clearfix">
                <div v-if="this.order.messages[message]['message_type']==='from_manager_to_customer'"
                     class="message other-message float-right">
                  <div v-for="article in this.order.messages[message]['message_text'].split('\n')" v-bind:key="article"
                       style="text-align: left">
                    <br v-if="article===''">
                    {{ article }}
                  </div>
                  <div class="message-data">
                    <span class="message-data-time" style="color: #efefef;">{{
                        String(new Date(this.order.messages[message]['send_at']).getDate()).padStart(2, '0') + '.' +
                        String(new Date(this.order.messages[message]['send_at']).getMonth() + 1).padStart(2, '0') + ' ' +
                        String(new Date(this.order.messages[message]['send_at']).getHours()).padStart(2, '0') + ':' +
                        String(new Date(this.order.messages[message]['send_at']).getMinutes()).padStart(2, '0')
                      }}</span>
                  </div>
                </div>

                <div v-if="this.order.messages[message]['message_type']==='from_admin_to_customer'"
                     class="message other-message float-right" style="background-color: #157347;">
                  <div v-for="article in this.order.messages[message]['message_text'].split('\n')" v-bind:key="article"
                       style="text-align: left">
                    <br v-if="article===''">
                    {{ article }}
                  </div>
                  <div class="message-data">
                    <span class="message-data-time" style="color: #efefef;">{{
                        String(new Date(this.order.messages[message]['send_at']).getDate()).padStart(2, '0') + '.' +
                        String(new Date(this.order.messages[message]['send_at']).getMonth() + 1).padStart(2, '0') + ' ' +
                        String(new Date(this.order.messages[message]['send_at']).getHours()).padStart(2, '0') + ':' +
                        String(new Date(this.order.messages[message]['send_at']).getMinutes()).padStart(2, '0')
                      }}</span>
                  </div>
                </div>

                <div v-if="this.order.messages[message]['message_type']==='from_customer_to_manager'"
                     class="message my-message">
                  <div v-for="article in this.order.messages[message]['message_text'].split('\n')" v-bind:key="article"
                       style="text-align: left">
                    <br v-if="article===''">
                    {{ article }}
                  </div>
                  <div class="message-data">
                    <span class="message-data-time">{{
                        String(new Date(this.order.messages[message]['send_at']).getDate()).padStart(2, '0') + '.' +
                        String(new Date(this.order.messages[message]['send_at']).getMonth() + 1).padStart(2, '0') + ' ' +
                        String(new Date(this.order.messages[message]['send_at']).getHours()).padStart(2, '0') + ':' +
                        String(new Date(this.order.messages[message]['send_at']).getMinutes()).padStart(2, '0')
                      }}</span>
                  </div>
                </div>

                <div v-if="this.order.messages[message]['message_type']==='from_manager_to_customer_with_attachment'"
                     class="message other-message float-right">
                  <div v-for="article in this.order.messages[message]['message_text'].split('\n')" v-bind:key="article"
                       style="text-align: left">
                    {{ article }}
                    <br v-if="article!==''">
                    <a href="#" style="vertical-align: middle; display: flex"
                       @click="download_file(this.order.messages[message]['filename_in_fs'], this.order.messages[message]['filename_from_user'])">
                      <div class="material-symbols-outlined" style="margin-right: 5px;">save</div>
                      <div style="display: inline-block;">
                        {{ this.order.messages[message]["filename_from_user"] }}
                      </div>
                    </a>
                  </div>
                  <div class="message-data">
                    <span class="message-data-time" style="color: #efefef;">{{
                        String(new Date(this.order.messages[message]['send_at']).getDate()).padStart(2, '0') + '.' +
                        String(new Date(this.order.messages[message]['send_at']).getMonth() + 1).padStart(2, '0') + ' ' +
                        String(new Date(this.order.messages[message]['send_at']).getHours()).padStart(2, '0') + ':' +
                        String(new Date(this.order.messages[message]['send_at']).getMinutes()).padStart(2, '0')
                      }}</span>
                  </div>
                </div>

                <div v-if="this.order.messages[message]['message_type']==='from_admin_to_customer_with_attachment'"
                     class="message other-message float-right" style="background-color: #157347">
                  <div v-for="article in this.order.messages[message]['message_text'].split('\n')" v-bind:key="article"
                       style="text-align: left">
                    {{ article }}
                    <br v-if="article!==''">
                    <a href="#" style="vertical-align: middle; display: flex"
                       @click="download_file(this.order.messages[message]['filename_in_fs'], this.order.messages[message]['filename_from_user'])">
                      <div class="material-symbols-outlined" style="margin-right: 5px;">save</div>
                      <div style="display: inline-block;">
                        {{ this.order.messages[message]["filename_from_user"] }}
                      </div>
                    </a>
                  </div>
                  <div class="message-data">
                    <span class="message-data-time" style="color: #efefef;">{{
                        String(new Date(this.order.messages[message]['send_at']).getDate()).padStart(2, '0') + '.' +
                        String(new Date(this.order.messages[message]['send_at']).getMonth() + 1).padStart(2, '0') + ' ' +
                        String(new Date(this.order.messages[message]['send_at']).getHours()).padStart(2, '0') + ':' +
                        String(new Date(this.order.messages[message]['send_at']).getMinutes()).padStart(2, '0')
                      }}</span>
                  </div>
                </div>

                <div v-if="this.order.messages[message]['message_type']==='from_customer_to_manager_with_attachment'"
                     class="message my-message">
                  <div v-for="article in this.order.messages[message]['message_text'].split('\n')" v-bind:key="article"
                       style="text-align: left">
                    {{ article }}
                    <br v-if="article!==''">
                    <a href="#" style="vertical-align: middle; display: flex; color: #444444"
                       @click="download_file(this.order.messages[message]['filename_in_fs'], this.order.messages[message]['filename_from_user'])">
                      <div class="material-symbols-outlined" style="margin-right: 5px;">save</div>
                      <div style="display: inline-block;">
                        {{ this.order.messages[message]["filename_from_user"] }}
                      </div>
                    </a>
                  </div>
                  <div class="message-data">
                    <span class="message-data-time">{{
                        String(new Date(this.order.messages[message]['send_at']).getDate()).padStart(2, '0') + '.' +
                        String(new Date(this.order.messages[message]['send_at']).getMonth() + 1).padStart(2, '0') + ' ' +
                        String(new Date(this.order.messages[message]['send_at']).getHours()).padStart(2, '0') + ':' +
                        String(new Date(this.order.messages[message]['send_at']).getMinutes()).padStart(2, '0')
                      }}</span>
                  </div>
                </div>

                <div v-if="this.order.messages[message]['message_type']==='notification_success'">
                  <div style="color: green">
                    {{ this.order.messages[message]["message_text"] }}
                  </div>
                </div>
                <div v-if="this.order.messages[message]['message_type']==='notification_danger'">
                  <div style="color: red">
                    {{ this.order.messages[message]["message_text"] }}
                  </div>
                </div>
                <div v-if="this.order.messages[message]['message_type']==='notification_info'">
                  <div>
                    {{ this.order.messages[message]["message_text"] }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="chat-message clearfix">
          <div class="input-group mb-0">
              <textarea type="text" ref="textarea" style="font-size: 1rem"
                        @keydown.enter.prevent.exact="send_message"
                        @keyup.ctrl.enter.prevent="new_line"
                        @keyup.shift.enter.prevent="new_line"
                        v-model=message
                        id="send_area"
                        placeholder="Введите сообщение...">
            </textarea>
            <button id="paperclip_button" onclick="document.getElementById('files_input').click()">
              <span><i style="font-size:1.2rem;" class="fa fa-paperclip"></i></span>
            </button>
            <button @click="send_message()" id="send_button">
              <span class="material-symbols-outlined" style="margin-right: 5px;">send</span>
            </button>
          </div>
          <div v-if="file" class="mt-1">
            <span class="material-symbols-outlined" style="margin-right: 5px;">description</span>
            {{ file['name'] }}
            <CCloseButton style="margin-left: 0" @click="this.file = ''" class="inline-element"/>
          </div>
          <input id="files_input" type="file" style="visibility: hidden" ref="file" v-on:change="handleFileUpload()">
        </div>
      </div>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/admin/ManagerPages/HeaderComponent";
import FooterComponent from "@/admin/ManagerPages/FooterComponent";
import axios from "axios";
import {io} from "socket.io-client";
import {CCloseButton} from "@coreui/vue";

const ws_path = "wss://конструктор.ленсиз.рф"  // ws://localhost:3000
const socket = io(ws_path, { // wss://конструктор.ленсиз.рф
  path: "/ws/socket.io",
  autoConnect: true,
})

const {pathname} = window.location;
const paths = pathname.split("/").filter(entry => entry !== "");
const order_num = Number(paths[paths.length - 1]);


export default {
  name: "CustomerChat",

  components: {HeaderComponent, FooterComponent, CCloseButton},

  data() {
    return {
      user: {},
      order: {},
      message: '',
      file: ''
    }
  },

  methods: {
    handleFileUpload() {
      if (this.$refs.file.files[0].size <= 20 * 1024 * 1024) {
        this.file = this.$refs.file.files[0];
      } else {
        alert("Максмальный размер файла 20МБ")
      }
    },

    download_file(filename_in_fs, filename_from_user) {
      axios.post("/api/download_file_customer/", {
            "token": localStorage.getItem("access_token"),
            "order_num": order_num,
            "filename_in_fs": filename_in_fs,
            "filename_for_user": filename_from_user,
          }, {responseType: 'blob'}, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((res) => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename_from_user);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
    },
    get_order() {
      axios.get("/api/admin/get_order/" + this.$route.params["id"] + '?' + "token=" + localStorage.getItem("access_token"), {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        this.order = response.data;
      }).then(() => {
        const element = document.getElementById("chat-box");
        element.scrollTop = element.scrollHeight;
        this.get_user()
      }).catch((reason) => {
        alert(reason.response.data.detail)
      })
    },

    send_message() {
      if (!this.file) {
        if (this.message) {
          socket.emit("send_message", {
            "order_num": order_num,
            "message": this.message,
            "token": localStorage.getItem("access_token")
          })
        }
      } else {
        socket.emit("send_message_with_attachment", {
          "order_num": order_num,
          "message": this.message,
          "token": localStorage.getItem("access_token"),
          "file": this.file,
          "filename": this.file['name']
        })
      }
      this.message = ''
      this.file = ''
    },

    get_user() {
      axios.post("/api/admin/get_user/", {
            "token": localStorage.getItem("access_token"),
            "email": this.order["email"]
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        this.user = response.data;
      })
    },

    new_line(e) {
      let caret = e.target.selectionStart;
      e.target.setRangeText("\n", caret, caret, "end");
      this.message = e.target.value;
    },
  },

  mounted() {
    socket.connect()

    socket.on("connect", () => {
      socket.emit("enter_room", {
        "order_num": order_num
      })
    })

    socket.on("receiveMsg", (msg) => {
      this.order.messages.push(msg);
      let element = document.getElementById("chat-box");
      element.scrollTop = element.scrollHeight;
    });

    this.get_order();

    let button = document.getElementById("send_button");
    button.style.height = document.getElementById("send_area").style.height;
  },

  watch: {
    message: function () {
      this.$refs.textarea.style.height = "auto";
      let button = document.getElementById("send_button");
      button.style.height = document.getElementById("send_area").style.height;

      this.$nextTick(() => {
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
      })
    },

    order: {
      handler() {
        let element = document.getElementById("chat-box");
        if (element) {
          setTimeout(function () {
            element.scrollTop = element.scrollHeight;
          }, 10);
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
#chat-box {
  padding: 20px;
  padding-bottom: 0;
  height: 56vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  outline: 1px solid slategrey;
  border-radius: 1rem;
}

#send_button {
  background: none;
  margin: 0;
  padding: 0;
  width: 5%;
  border: 1px solid #ccc;
  border-radius: 0 6px 6px 0;
  border-left: none;
}

#paperclip_button {
  background: none;
  margin: 0;
  padding: 0;
  width: 5%;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
}

#send_area {
  resize: none;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  display: inline-block;
  background-color: white;
  background-color: -moz-field;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 6px 0 0 6px;
  font-size: .9rem;
  padding: .8rem .5rem;
  width: 90%;
  height: 100%;
  max-height: 200px;
}

#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 5vw;
  min-height: 85vh;
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px
}


.people-list img {
  float: left;
  border-radius: 50%
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px
}

.chat .chat-history {
  border-bottom: 2px solid #fff
}

.chat .chat-history ul {
  padding: 0
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0
}

.chat .chat-history .message-data-time {
  color: #434651;
  font-size: 14px;
}

.chat .chat-history .message {
  color: #444;
  padding: 10px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative
}


.chat .chat-history .my-message {
  background: #efefef
}

.chat .chat-history .other-message {
  background: #0b5ed7;
  color: #efefef;
  text-align: right
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%
}

.chat .chat-message {
}

.float-right {
  float: right
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0
}
</style>