<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; padding-left: 10%; color: #24509c">
    Чаты с поставщиками</h1>
  <div id="wrapper">
    <AdminMenuComponent/>

    <div>
      <div style="width: 75vw; vertical-align: center; text-align: center">
        <div class="form-group" style="display: inline-block">
          <input class="form-control" placeholder="Наименование заказчика" type="text" v-model="name_of_customer"/>
        </div>
        <div class="form-group" style="display: inline-block; ; margin-left: 20px; ; margin-right: 20px">
          <input class="form-control" placeholder="Наименование поставщика" type="text" v-model="name_of_company"/>
        </div>
        <div style="display: inline-block; margin-right: 20px">
          <input v-model="unread_messages" @change="update_orders(current_page)" type="checkbox"
                 class="form-check" style="display: inline-block; vertical-align: middle; margin: 5px 5px 5px 0;">
          <div style="display: inline-block; vertical-align: middle">
            Непрочитанные сообщения
          </div>
        </div>
        <div style="display: inline-block; margin-right: 20px">
          <input v-model="need_to_check_decision" @change="update_orders(current_page)" type="checkbox"
                 class="form-check" style="display: inline-block; vertical-align: middle; margin: 5px 5px 5px 0;">
          <div style="display: inline-block; vertical-align: middle">
            Необходимо принять решение
          </div>
        </div>
        <button class="btn btn-danger"
                style="height: 100%; width: 100px; background-color: green; border: 0; margin-bottom: 3px"
                @click="update_orders(this.current_page)">
          Поиск
        </button>
      </div>
      <br>
      <div class="form_radio_group">
        <div class="form_radio_group-item">
          <input id="radio-0" type="radio" value="-1" @click="orders_status=-1; update_page()"
                 :checked="orders_status===-1">
          <label for="radio-0">Все</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-1" type="radio" value="0" @click="orders_status=0; update_page()"
                 :checked="orders_status===0">
          <label for="radio-1">На оценке</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-2" type="radio" value="1" @click="orders_status=1; update_page()"
                 :checked="orders_status===1">
          <label for="radio-2">Отказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-3" type="radio" value="2" @click="orders_status=2; update_page()"
                 :checked="orders_status===2">
          <label for="radio-3">Принятые заказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-4" type="radio" value="3" @click="orders_status=3; update_page()"
                 :checked="orders_status===3">
          <label for="radio-4">На пошиве</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-5" type="radio" value="4" @click="orders_status=4; update_page()"
                 :checked="orders_status===4">
          <label for="radio-5">Завершенные заказы</label>
        </div>
      </div>

      <div style="width: 100%;">
        <table class="table" style="width: 100%">
          <thead>
          <th scope="col">ID</th>
          <th scope="col">Название макета</th>
          <th scope="col">Заказчик</th>
          <th scope="col">Поставщик</th>
          <th scope="col">Последнее сообщение</th>
          <th scope="col"></th>
          </thead>
          <tbody>
          <tr v-for="project in companies_orders" :key="project['_id']" style="width: 100%;"
              :class="check_if_unread(project['read_by_admin'])">
            <td> {{ project["customers_order_id"] }}</td>
            <td> {{ project["name"] }}</td>
            <td> {{ project["customer_name"] }}</td>
            <td> {{ project["company_name"] }}</td>
            <td> {{ new Date(project["last_message_at"]).toLocaleString().replace(',',' ') }}</td>
            <td>
              <a :href="'/admin/company_chat/' + project['_id']"
                 style="color: whitesmoke; padding: 7px; border-radius: 5px; background-color: #0353b2; border: 0">
                Открыть чат
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <br>
      <div v-for="page in Math.ceil(companies_orders_count/current_limit)" v-bind:key="page"
           style="display: inline-block;">
        <a @click="update_orders(page)" :class="{ page_button: true, active: page===current_page}">{{ page }} </a>
      </div>
    </div>

  </div>
  <FooterComponent/>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'CompaniesPage',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      name_of_company: "",
      name_of_customer: "",

      companies_orders: [],
      companies_orders_count: 0,
      current_page: 1,
      current_limit: 60,
      unread_messages: false,
      need_to_check_decision: false,

      orders_status: -1
    }
  },

  methods: {
    update_page() {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("orders_status", this.orders_status);
      window.location.search = searchParams.toString();
    },

    get_orders: function () {
      let find_options = {
        "customer_name": {$regex: `${this.name_of_customer}`, $options: 'i'},
        "company_name": {$regex: `${this.name_of_company}`, $options: 'i'},
      }

      if (new URLSearchParams(window.location.search).get("orders_status")) {
        this.orders_status = Number(new URLSearchParams(window.location.search).get("orders_status"));
      }

      if (this.orders_status !== -1) {
        find_options["status"] = this.orders_status
      }

      if (this.unread_messages === true) {
        find_options["read_by_admin"] = false;
      }
      if (this.need_to_check_decision === true) {
        find_options["$expr"] = {"$ne": ["$company_status", "$status"]}
      }
      axios.post("/api/admin/companies_orders", {
            "page": this.current_page,
            "limit": this.current_limit,
            "find_options": find_options,
          }, {
            headers: {}
          }
      ).then((response) => {
        this.companies_orders = response.data["companies_orders"];
        this.companies_orders_count = response.data["companies_orders_count"];
      })
    },

    update_orders(page) {
      this.current_page = page;
      this.get_orders()
    },

    check_if_unread(bool_condition) {
      return bool_condition === false ? "table-active" : "";
    }
  },

  mounted() {
    this.get_orders()
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}

.table > tbody > tr.table-active > td {
  --bs-table-bg-state: #FFDB93;
}

.form_radio_group {
  display: inline-block;
  overflow: hidden;
}

.form_radio_group-item {
  display: inline-block;
  float: left;
}

.form_radio_group input[type=radio] {
  display: none;
}

.form_radio_group label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  user-select: none;
}

.form_radio_group .form_radio_group-item:first-child label {
  border-radius: 6px 0 0 6px;
}

.form_radio_group .form_radio_group-item:last-child label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
  background: #ffe0a6;
}

/* Hover */
.form_radio_group label:hover {
  color: #666;
}

/* Disabled */
.form_radio_group input[type=radio]:disabled + label {
  background: #efefef;
  color: #666;
}
</style>