<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div class="form-check-inline" id="content" style="width: 100%;">
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/customer/HeaderComponent";
import MenuComponent from "@/customer/MenuComponent";
import FooterComponent from "@/customer/FooterComponent";
import axios from "axios";

export default {
  name: "CompanyOffer",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      offer: ""
    }
  },

  methods: {
    get_offer() {
      axios.get("/api/admin/customer_offer", {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => {
        let e = document.getElementById("content");
        e.innerHTML = response.data;
      }).catch((reason) => console.log(reason))
    },
  },

  mounted() {
    this.get_offer()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}
</style>