<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div style="width: 100%;">
      Видеоинструкция для компании
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/company/HeaderComponent";
import MenuComponent from "@/company/MenuComponent";
import FooterComponent from "@/company/FooterComponent";


export default {
  name: "CreatedOrders",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      user: {}
    }
  },

  methods: {
    get_user() {
    },
  },

  mounted() {
    this.get_user()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}
</style>