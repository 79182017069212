<template>
  <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"/>

  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Список производителей</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div>
      <div style="width: 75vw; vertical-align: center; text-align: center">
        <div class="form-group" style="display: inline-block">
          <input class="form-control" placeholder="Наименование" type="text" v-model="name_of_company"/>
        </div>
        <div class="form-group" style="display: inline-block; margin-left: 20px">
          <input class="form-control" placeholder="ИНН" type="text" v-model="TIN_of_company"/>
        </div>
        <div class="form-group" style="display: inline-block; margin-left: 20px">
          <input class="form-control" placeholder="Описание" type="text" v-model="about_company"/>
        </div>
        <button class="btn btn-danger"
                style="height: 100%; width: 100px; background-color: green; border: 0; margin-bottom: 3px; margin-left: 20px"
                @click="update_users(this.current_page)">
          Поиск
        </button>
      </div>

      <br>

      <a href="#" @click="this.delivered = false;" data-bs-toggle="modal" data-bs-target="#sendManyModal"
         style="color: black">
        Отправить выбранным пользователям
      </a>

      <div style="width: 100%;">
        <table class="table" style="width: 100%">
          <thead>
          <th scope="col"></th>
          <th scope="col">ID</th>
          <th scope="col">Компания</th>
          <th scope="col">ИНН</th>
          <th scope="col">Телефон</th>
          <th scope="col">Описание</th>
          <th scope="col"></th>
          </thead>
          <tbody>
          <tr v-for="user in Object.keys(users)" v-bind:key="user" style="width: 100%;">
            <td><input type="checkbox" class="form-check" v-model="users_checked[user]"></td>
            <td> {{ users[user]["_id"] }}</td>
            <td> {{ users[user]["name"] }}</td>
            <td> {{ users[user]["TIN"] }}</td>
            <td> {{ users[user]["phone"] }}</td>
            <td> {{ users[user]["about"] }}</td>
            <td>
              <a v-if="!users[user]['orders'].map(order => order['customer_order_id']).includes(Number(this.$route.query.order))"
                 @click="open_modal(users[user]['_id'])" type="button" href="#"
                 data-bs-toggle="modal" data-bs-target="#sendModal" style="color: black">
                Отправить сообщение с формой заказа
              </a>
              <a v-else href="#" style="padding-top: 0; margin-top: 0; color: black" @click="open_modal(users[user]['_id'])" data-bs-toggle="modal"  data-bs-target="#sendModal" >
                Уже отправлено. Отправить повторно?
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <br>
      <div v-for="page in Math.ceil(users_count/current_limit)" v-bind:key="page" style="display: inline-block;">
        <a @click="update_users(page)" :class="{ page_button: true, active: page===current_page}">{{ page }} </a>
      </div>
    </div>

  </div>

  <div class="modal fade" id="sendModal" tabindex="-1" aria-hidden="false">
    <div class="modal-dialog" style="height: 20rem; padding: 0; margin: 0;">
      <div class="modal-content" v-if="delivered===false">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <h5 class="modal-title" style="text-align: center">Введите сообщение производителю</h5>
        <textarea v-model="text_to_company" class="form-control"
                  style="resize: none; height: 16rem; margin: 10px; width: 96%"></textarea>

        <button class="btn btn-primary" @click="send_message" style="font-size: 17px; margin: 0 10px 10px 10px">
          Отправить
        </button>
      </div>

      <div class="modal-content" v-else>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <div style="text-align: center; margin: auto;  color: green">
          <p style="vertical-align: middle; font-size: 40px;">
            Успешно доставлено!
          </p>
          <span class="material-symbols-outlined" style="vertical-align: middle; font-size: 80px;">check_circle</span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="sendManyModal" tabindex="-1" aria-hidden="false">
    <div class="modal-dialog" style="height: 20rem; padding: 0; margin: 0;">
      <div class="modal-content" v-if="delivered===false">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <h5 class="modal-title" style="text-align: center">Введите сообщение производителям</h5>
        <textarea v-model="text_to_company" class="form-control"
                  style="resize: none; height: 16rem; margin: 10px; width: 96%"></textarea>

        <button class="btn btn-primary" @click="send_many_messages" style="font-size: 17px; margin: 0 10px 10px 10px">
          Отправить
        </button>
      </div>

      <div class="modal-content" v-else>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <div style="text-align: center; margin: auto;  color: green">
          <p style="vertical-align: middle; font-size: 40px;">
            Успешно доставлено!
          </p>
          <span class="material-symbols-outlined" style="vertical-align: middle; font-size: 80px;">check_circle</span>
        </div>
      </div>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'SelectCompanyForOrder',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      users: [],
      users_checked: [],
      users_count: 0,
      current_page: 1,
      current_limit: 60,
      name_of_company: '',
      TIN_of_company: '',
      about_company: '',

      delivered: false,
      selected_company: -1,
      text_to_company: ''
    }
  },

  methods: {
    get_users: function () {
      axios.post("/api/admin/companies", {
            "page": this.current_page,
            "limit": this.current_limit,
            "find_options": {
              "name": {$regex: `${this.name_of_company}`, $options: 'i'},
              "TIN": {$regex: `${this.TIN_of_company}`, $options: 'i'},
              "about": {$regex: `${this.about_company}`, $options: 'i'}
            },
          }, {
            headers: {}
          }
      ).then((response) => {
        this.users = response.data["users"];
        this.users_count = response.data["users_count"];

        this.users_checked = new Array(this.users_count).fill(false);
      })
    },

    open_modal(selected_company) {
      this.selected_company = selected_company;
      this.delivered = false;
    },

    send_message() {
      axios.post("/api/admin/send_order_to_company", {
            "text": String(this.text_to_company),
            "order": Number(this.$route.query.order),
            "companies": [Number(this.selected_company)],
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        if (response.status === 201) {
          this.delivered = true;

          for (let user in this.users) {
            if (this.users[user]["_id"] === this.selected_company) {
              this.users[user]["orders"].push({"customer_order_id": Number(this.$route.query.order)})
            }
          }
        }
      })
    },

    send_many_messages() {
      let users_ids = [];
      for (let user in this.users_checked) {
        if (this.users_checked[user]) {
          users_ids.push(Number(this.users[user]["_id"]))
        }
      }

      axios.post("/api/admin/send_order_to_company", {
            "text": String(this.text_to_company),
            "order": Number(this.$route.query.order),
            "companies": users_ids,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        if (response.status === 201) {
          this.delivered = true;

          for (let user in this.users) {
            if (users_ids.includes(this.users[user]["_id"])) {
              this.users[user]["orders"].push({"customer_order_id": Number(this.$route.query.order)})
            }
          }
        }
      })
    },

    update_users(page) {
      this.current_page = page;
      this.get_users()
    },
  },

  mounted() {
    this.get_users()
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}

.modal {
  height: 20rem;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 8px;
  transform: translate(-50%, -50%);
  position: fixed;
  overflow: auto;
  left: 50%;
  top: 50%;
}

.modal-close {
  border-radius: 50%;
  color: #fff;
  background: #2a4cc7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modal-title {
  color: #0971c7;
}

.modal-content {
  height: 20rem;
  padding: 0;
  margin: 0 20px 0 0
}

</style>