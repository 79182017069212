<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div style="width: 100%;">
      <h1 class="display-1"
          style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">Мои
        заказы</h1>

      <div class="form_radio_group">
        <div class="form_radio_group-item">
          <input id="radio-0" type="radio" value="-1" @click="orders_status=-1; update_page()"
                 :checked="orders_status===-1">
          <label for="radio-0">Все</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-1" type="radio" value="0" @click="orders_status=0; update_page()"
                 :checked="orders_status===0">
          <label for="radio-1">На оценке</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-2" type="radio" value="1" @click="orders_status=1; update_page()"
                 :checked="orders_status===1">
          <label for="radio-2">Отказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-3" type="radio" value="2" @click="orders_status=2; update_page()"
                 :checked="orders_status===2">
          <label for="radio-3">Принятые заказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-4" type="radio" value="3" @click="orders_status=3; update_page()"
                 :checked="orders_status===3">
          <label for="radio-4">На пошиве</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-5" type="radio" value="4" @click="orders_status=4; update_page()"
                 :checked="orders_status===4">
          <label for="radio-5">Завершенные заказы</label>
        </div>
      </div>

      <table class="table" style="width: 100%">
        <thead>
        <th scope="col">ID</th>
        <th scope="col">Название</th>
        <th scope="col">Дата последнего сообщения</th>
        <th scope="col"></th>
        </thead>
        <tbody>
        <tr v-for="project in projects" :key="project['_id']" style="width: 100%;"
            :class="check_if_unread(project['read_by_client'])">
          <td> {{ project["customers_order_id"] }}</td>
          <td> {{ project["name"] }}</td>
          <td> {{ new Date(project["last_message_at"]).toLocaleString().replace(',',' ') }}</td>
          <td>
            <a :href="'/company/project/' + project['_id']"
               style="color: whitesmoke; padding: 7px; border-radius: 5px; background-color: #0353b2; border: 0">
              Открыть чат
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/company/HeaderComponent";
import MenuComponent from "@/company/MenuComponent";
import FooterComponent from "@/company/FooterComponent";
import axios from "axios";

export default {
  name: "CreatedOrders",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      projects: [],

      orders_status: -1
    }
  },

  methods: {
    update_page() {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("orders_status", this.orders_status);
      window.location.search = searchParams.toString();
    },

    update_orders() {
      let find_options = {};
      if (new URLSearchParams(window.location.search).get("orders_status")) {
        this.orders_status = Number(new URLSearchParams(window.location.search).get("orders_status"));
      }
      if (this.orders_status !== -1) {
        find_options["status"] = this.orders_status;
      }

      axios.post("/api/company/get_orders", {
            "token": localStorage.getItem("access_token"),
            "find_options": find_options
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        this.projects = response.data
      })
    },

    check_if_unread(bool_condition) {
      return bool_condition === false ? "table-active" : "";
    }
  },

  mounted() {
    this.update_orders();
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.table > tbody > tr.table-active > td {
  --bs-table-bg-state: #FFDB93;
}


.form_radio_group {
  display: inline-block;
  overflow: hidden;
}

.form_radio_group-item {
  display: inline-block;
  float: left;
}

.form_radio_group input[type=radio] {
  display: none;
}

.form_radio_group label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  user-select: none;
}

.form_radio_group .form_radio_group-item:first-child label {
  border-radius: 6px 0 0 6px;
}

.form_radio_group .form_radio_group-item:last-child label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
  background: #ffe0a6;
}

/* Hover */
.form_radio_group label:hover {
  color: #666;
}
</style>