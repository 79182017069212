<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div class="form-check-inline" style="width: 30vw">
      <h3>Личный кабинет производителя</h3>
      <div class="form-group">
        <label><b>Наименование компании</b></label>
        <input class="form-control" type="text" v-model="this.user['name']"/>
      </div>

      <div class="form-group">
        <label><b>ИНН</b></label>
        <input class="form-control" type="text" v-model="this.user['TIN']"/>
      </div>

      <div class="form-group">
        <label><b>Почта</b></label>
        <input class="form-control" type="text" v-model="this.user['email']"/>
      </div>

      <div class="form-group">
        <label><b>Контактное лицо</b></label>
        <input class="form-control" type="text"
               v-model="this.user['contact_person']"/>
      </div>

      <div class="form-group">
        <label><b>Должность</b></label>
        <input class="form-control" type="text"
               v-model="this.user['position']"/>
      </div>

      <div class="form-group">
        <label><b>Телефон</b></label>
        <input class="form-control" type="text" v-model="this.user['phone']"/>
      </div>

      <div>
        <a href="/company/password_edit" class="btn btn-primary mt-3 me-3" style="background-color: #0353b2">
          Поменять пароль
        </a>
        <a href="/company/profile_edit" class="btn btn-primary mt-3" style="background-color: #0353b2">
          Изменить контактное лицо
        </a>
      </div>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/company/HeaderComponent";
import MenuComponent from "@/company/MenuComponent";
import FooterComponent from "@/company/FooterComponent";
import axios from "axios";

export default {
  name: "ProfilePage",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      user: {}
    }
  },

  methods: {
    get_user() {
      axios.post("/api/company/profile", {
            "token": localStorage.getItem("access_token"),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => this.user = response.data)
    }
  },

  mounted() {
    this.get_user()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}
</style>